import { Injectable } from '@angular/core';
import { LocalStorageRepository } from '../repositories/local-storage.repository';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    allowedRoles = ['company', 'admin'];

    modelsPath = {
        Calendar: '/calendar',
        Dashboard: '/',
        Event: '/events',
        Customer: '/management/customers',
        EventType: '/management/event-types',
        Organizer: '/management/organizers',
        RateManagement: '/management/rate-management',
        Order: '/management/orders',
        Payment: '/management/payments',
        StaffGroup: '/management/staff-groups',
        Staff: '/management/staff',
        Settings: '/management/settings/general',
        Log: '/management/logs',
        SeatTemplate: '/management/seat-template',
        SeatType: '/management/seat-types',
        ClosedDateManagement: '/management/closed-date-management',
        Ticket: '/sell-tickets',
        TicketType: '/management/ticket',
        CustomerGroup: '/management/customer-groups'
    };
    private user: any;

    constructor() {
        this.user = LocalStorageRepository.getUser();
    }

    isAccessAllowed(page: string, action: any): boolean {
        return (
            this.user &&
            (this.hasRole(this.user) ||
                this.hasPermission(this.user, page, action))
        );
    }

    hasRole(user: any): boolean {
        return user.role && this.allowedRoles.includes(this.user.role);
    }

    hasPermission(user: any, page: string, action: string): boolean {
        return (
            user.permissions &&
            user.permissions[page] &&
            user.permissions[page][action]
        );
    }

    setUser(user: any) {
        this.user = user;
    }

    getAllowedModels() {
        let allowedPermissions = [];

        if (this.user && this.user.permissions) {
            Object.keys(this.user.permissions).forEach((page) => {
                if (this.user.permissions[page]['view']) {
                    allowedPermissions.push(`${page}`);
                }
            });
        }

        return allowedPermissions;
    }
}
