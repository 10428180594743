import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import { MatDialogModule } from "@angular/material/dialog";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import en from "@angular/common/locales/en";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule, DatePipe, DecimalPipe, registerLocaleData} from "@angular/common";
import {en_US, NZ_I18N} from "ng-zorro-antd/i18n";
import {NZ_ICONS} from "ng-zorro-antd/icon";
import { NzMessageModule } from "ng-zorro-antd/message";
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import { ApiUrlInterceptor } from "./common/interceptors/api-url-interceptor";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MY_FORMATS } from "./common/utils/date-utils";
import { environment } from "../environments/environment";

registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatDialogModule,
        NzMessageModule,
        MatGoogleMapsAutocompleteModule.forRoot(environment.googlePlaceApiKey),
        AppRoutingModule
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        { provide: NZ_I18N, useValue: en_US },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptor,
            multi: true
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering:'false'}},
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
