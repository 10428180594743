import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseNetworkRepository } from './base-network-repository';
import { Settings } from '../models/settings';
import { GlobalOneSettings } from '../models/global-one-settings';
import { PaymentType } from '../models/settings-payment-types.model';
import { PaymentSettings } from '../models/payment-settings';
import { map } from 'rxjs/operators';
import { VippsSalesUnitsInfo } from '../models/vipps-sales-units-info';

@Injectable({
    providedIn: "root"
})
export class SettingsRepository extends BaseNetworkRepository {
    constructor(public http: HttpClient) {
        super(http);
    }

    loadGeneralSettings(headers?: any): Observable<any> {
        return this.get<Settings>(`/settings/get-general`, true, headers);
    }

    getG1Settings(): Observable<GlobalOneSettings> {
        return this.get<GlobalOneSettings>(`/settings/get-g1-settings`, true);
    }

    getEmailSettings(): Observable<any> {
        return this.get<any>(`/settings/get-email-template`, true);
    }

    getOnlineBookingSettings(): Observable<any> {
        return this.get<any>(`/settings/get-online-booking`, true);
    }

    getPaymentSettings(): Observable<PaymentSettings[]> {
        return this
            .get<any>(
                `/settings/get-payment-settings`,
                true)
            .pipe(
                map(paymentSettings => paymentSettings
                    .map((ps: PaymentSettings) => new PaymentSettings(ps)))
            );
    }

    setGeneralSettings(formData: any, headers?: any): Observable<any> {
        return this.post<any>(
			`/settings/set-general`, 
			formData, 
			true, 
			headers);
    }

    setEmailSettings(formData: any, headers?: any): Observable<any> {
        return this.post<any>(
			`/settings/set-email-template`, 
			formData, 
			true, 
			headers);
    }

    setOnlineBookingSettings(formData: any, headers?: any): Observable<any> {
        return this.post<any>(
			`/settings/set-online-booking`, 
			formData, 
			true, 
			headers);
    }

    setPaymentSettings(formData: any, headers?: any): Observable<any> {
        return this.post<any>(
			`/settings/set-payment-settings`, 
			formData, 
			true, 
			headers);
    }

    startG1Integration(login: string, password: string, shopKey: string) {
        return this.post(
			`/user-settings/start-g1-integration`,
			{
				login,
				password,
				shopKey,
			},
			true);
    }

    stopG1Integration(login: string, password: string, shopKey: string) {
        return this.post(
            `/user-settings/stop-g1-integration`,
            {
                login,
                password,
                shopKey,
            },
            true);
    }

    getVippsMerchantByMSN(merchantSerialNumber: string): Observable<VippsSalesUnitsInfo> {
        return this
            .get<any>(
                `/vipps/get-merchant-by-msn/${merchantSerialNumber}`,
                true);
    }

}